import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function PhoneIcon({ color = "#000000" }) {
  return (
    <SvgIcon>
      {/* Icon Credit: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools */}
      <svg
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.2747 12.3524C15.9352 11.1532 16.2998 9.7292 16.2998 8.14889L14.9909 7.82163C14.642 7.7344 14.2823 7.91833 14.1487 8.25226L13.5761 9.68373C13.4812 9.92096 13.5193 10.1907 13.6762 10.3924L15.2747 12.3524ZM15.2747 12.3524C14.5386 13.6887 13.4349 14.7457 12.0588 15.4283M12.0588 15.4283C10.9231 15.9916 9.60189 16.3 8.14849 16.3L7.82138 14.9912C7.73415 14.6423 7.91808 14.2825 8.25202 14.149L9.6262 13.5993C9.89333 13.4924 10.1984 13.5551 10.4019 13.7585L12.0588 15.4283ZM7.19902 3.99927H16.799C17.9191 3.99927 18.4792 3.99927 18.907 4.21725C19.2833 4.409 19.5893 4.71496 19.781 5.09129C19.999 5.51911 19.999 6.07916 19.999 7.19927V16.7993C19.999 17.9194 19.999 18.4794 19.781 18.9072C19.5893 19.2836 19.2833 19.5895 18.907 19.7813C18.4792 19.9993 17.9191 19.9993 16.799 19.9993H7.19902C6.07892 19.9993 5.51887 19.9993 5.09104 19.7813C4.71472 19.5895 4.40876 19.2836 4.21701 18.9072C3.99902 18.4794 3.99902 17.9194 3.99902 16.7993V7.19927C3.99902 6.07916 3.99902 5.51911 4.21701 5.09129C4.40876 4.71496 4.71472 4.409 5.09104 4.21725C5.51887 3.99927 6.07892 3.99927 7.19902 3.99927Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
